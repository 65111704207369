import React, { useContext, useState } from 'react'
import { HeaderMenu } from '../../component/HeaderMenu'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import LoginContext from '../../context/login_context/LoginContext'
import { URL_SRV_RAIZ } from '../../router/Url'

const userList = [
  "ABRAHAM CORDOVA",
  "ALAN AROS",
  "ANDRES MELILLAN",
  "ANGEL CORNEJO",
  "ANGELO ROBERTS",
  "ARIEL BARRERA",
  "BENJAMIN LOPEZ",
  "BRIAN URBINA",
  "CAMILA PINTO",
  "CARLOS DIAZ",
  "CARLOS GALLEGUILLOS",
  "CARLOS NUÑEZ",
  "CAROL MESA",
  "CATALINA CHACON",
  "CLAUDIO CID",
  "CLAUDIO OLIVARES",
  "CRISTOPHER DONOSO",
  "DANIEL GONZALEZ",
  "DANIEL MATUS",
  "DAVID ROJAS",
  "DIEGO SILVA",
  "EMILIO CARRENO",
  "EMMANUEL MELLADO",
  "FERNANDO HENRIQUEZ",
  "FRANCISCO ACEVEDO",
  "GABRIEL ARAYA",
  "GABRIEL AROS",
  "IGNACIO VEGA",
  "IGNACIO VERA",
  "ISIDORA YANEZ",
  "ISMAEL SANTIS",
  "JAVIER VERA",
  "JOEL ARMIJO",
  "JUAN AHUMADA",
  "JUAN VERA",
  "JULIO SOTO",
  "KEVYN TRUJILLO",
  "LUIS RODRIGUEZ",
  "LUIS SILVA",
  "MAIKOL ARANEDA",
  "MARIO BUSTOS",
  "MATIAS VERDEJO",
  "MAURICIO ALFARO",
  "MAURICIO FUENTES",
  "MAURICIO MAYOLAFQUEN",
  "MICHEL CARVAJAL",
  "MIGUEL SALAS",
  "NATALIA NAVARRO",
  "NICOLAS ROJAS",
  "PABLO MALAGUENO",
  "PEDRO GUTIERREZ",
  "PEDRO HUERTA",
  "ROBERTO CASTRO",
  "SEBASTIAN GUAJARDO",
  "VANESA MATURANA",
]

export const Home = () => {
  const {LoginState,SetLogin,CloseLogin,GetLoginSession} = useContext(LoginContext);
  const [user, setUser] = useState(LoginState.user);
  const [Categoria_1, setCategoria_1] = useState("");
  const [Categoria_2, setCategoria_2] = useState("");
  const [Categoria_3, setCategoria_3] = useState("");
  const [Categoria_4, setCategoria_4] = useState("");
  const [Categoria_5, setCategoria_5] = useState("");
  const [Categoria_6, setCategoria_6] = useState("");
  const [Categoria_7, setCategoria_7] = useState("");
  const [Categoria_8, setCategoria_8] = useState("");
  const [Categoria_9, setCategoria_9] = useState("");
  const [Categoria_10, setCategoria_10] = useState("");

  const handleSendData = async () => {
    return new Promise(async (resolve, reject) => {
      let query = await fetch(URL_SRV_RAIZ+'guarda_dito',{
        method: 'POST',
        headers: {
          'authorization': "marcuxo",
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          user,
          Categoria_1, Categoria_2, Categoria_3, Categoria_4, Categoria_5,
          Categoria_6, Categoria_7, Categoria_8, Categoria_9, Categoria_10,
        })
      })
      let responsito = await query.json();
  
      if(responsito.data.success){
        alert("Gracias 😎")
        await setCategoria_1("")
        await setCategoria_2("")
        await setCategoria_3("")
        await setCategoria_4("")
        await setCategoria_5("")
        await setCategoria_6("")
        await setCategoria_7("")
        await setCategoria_8("")
        await setCategoria_9("")
        await setCategoria_10("")
      }else{
        
      }
    })
  }
  /**
  return (
    <>
      <HeaderMenu Pag_Sel={'home'} />
      <div className='container'>
        <div className='row'>
          <div className='col-12 py-3 my-2' style={{border: '1px solid gray', borderRadius:10}}>
            <div className='row'>
              <div className='col-12 col-md-6'>1 EL MAS ESPINITA</div>
              <div className='col-12 col-md-6'>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label" size='small'>Postulante</InputLabel>
                  <Select
                    size='small'
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Postulante"
                    value={Categoria_1}
                    onChange={(e)=>setCategoria_1(e.target.value)}
                  >
                    {
                      userList.map((usr, index)=><MenuItem key={index} value={usr}>{usr}</MenuItem>)
                    }
                    

                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
          <div className='col-12 py-3 my-2' style={{border: '1px solid gray', borderRadius:10}}>
            <div className='row'>
              <div className='col-12 col-md-6'>2 EL QUE ESTA PERO NO ESTA</div>
              <div className='col-12 col-md-6'>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label" size='small'>Postulante</InputLabel>
                  <Select
                    size='small'
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Postulante"
                    value={Categoria_2}
                    onChange={(e)=>setCategoria_2(e.target.value)}
                  >
                    {
                      userList.map((usr, index)=><MenuItem key={index} value={usr}>{usr}</MenuItem>)
                    }
                    

                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
          <div className='col-12 py-3 my-2' style={{border: '1px solid gray', borderRadius:10}}>
            <div className='row'>
              <div className='col-12 col-md-6'>3 EL MAS HAMBRIENTO</div>
              <div className='col-12 col-md-6'>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label" size='small'>Postulante</InputLabel>
                  <Select
                    size='small'
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Postulante"
                    value={Categoria_3}
                    onChange={(e)=>setCategoria_3(e.target.value)}
                  >
                    {
                      userList.map((usr, index)=><MenuItem key={index} value={usr}>{usr}</MenuItem>)
                    }
                    

                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
          <div className='col-12 py-3 my-2' style={{border: '1px solid gray', borderRadius:10}}>
            <div className='row'>
              <div className='col-12 col-md-6'>4 EL MAS PRENDIDO</div>
              <div className='col-12 col-md-6'>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label" size='small'>Postulante</InputLabel>
                  <Select
                    size='small'
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Postulante"
                    value={Categoria_4}
                    onChange={(e)=>setCategoria_4(e.target.value)}
                  >
                    {
                      userList.map((usr, index)=><MenuItem key={index} value={usr}>{usr}</MenuItem>)
                    }
                    

                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
          <div className='col-12 py-3 my-2' style={{border: '1px solid gray', borderRadius:10}}>
            <div className='row'>
              <div className='col-12 col-md-6'>5 EL MEJOR COMPAÑERO</div>
              <div className='col-12 col-md-6'>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label" size='small'>Postulante</InputLabel>
                  <Select
                    size='small'
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Postulante"
                    value={Categoria_5}
                    onChange={(e)=>setCategoria_5(e.target.value)}
                  >
                    {
                      userList.map((usr, index)=><MenuItem key={index} value={usr}>{usr}</MenuItem>)
                    }
                    

                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
          <div className='col-12 py-3 my-2' style={{border: '1px solid gray', borderRadius:10}}>
            <div className='row'>
              <div className='col-12 col-md-6'>6 EL MAS CREATIVO <small>(MASTRO CHASQUILLA, LAS HACE TODAS)</small></div>
              <div className='col-12 col-md-6'>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label" size='small'>Postulante</InputLabel>
                  <Select
                    size='small'
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Postulante"
                    value={Categoria_6}
                    onChange={(e)=>setCategoria_6(e.target.value)}
                  >
                    {
                      userList.map((usr, index)=><MenuItem key={index} value={usr}>{usr}</MenuItem>)
                    }
                    

                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
          <div className='col-12 py-3 my-2' style={{border: '1px solid gray', borderRadius:10}}>
            <div className='row'>
              <div className='col-12 col-md-6'>7 EL MAS ALEGRE</div>
              <div className='col-12 col-md-6'>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label" size='small'>Postulante</InputLabel>
                  <Select
                    size='small'
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Postulante"
                    value={Categoria_7}
                    onChange={(e)=>setCategoria_7(e.target.value)}
                  >
                    {
                      userList.map((usr, index)=><MenuItem key={index} value={usr}>{usr}</MenuItem>)
                    }
                    
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
          <div className='col-12 py-3 my-2' style={{border: '1px solid gray', borderRadius:10}}>
            <div className='row'>
              <div className='col-12 col-md-6'>8 EL MEJOR VESTIDO</div>
              <div className='col-12 col-md-6'>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label" size='small'>Postulante</InputLabel>
                  <Select
                    size='small'
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Postulante"
                    value={Categoria_8}
                    onChange={(e)=>setCategoria_8(e.target.value)}
                  >
                    {
                      userList.map((usr, index)=><MenuItem key={index} value={usr}>{usr}</MenuItem>)
                    }
                    

                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
          <div className='col-12 py-3 my-2' style={{border: '1px solid gray', borderRadius:10}}>
            <div className='row'>
              <div className='col-12 col-md-6'>9 EL LOLOSAURIO</div>
              <div className='col-12 col-md-6'>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label" size='small'>Postulante</InputLabel>
                  <Select
                    size='small'
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Postulante"
                    value={Categoria_9}
                    onChange={(e)=>setCategoria_9(e.target.value)}
                  >
                    {
                      userList.map((usr, index)=><MenuItem key={index} value={usr}>{usr}</MenuItem>)
                    }
                    

                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
          <div className='col-12 py-3 my-2' style={{border: '1px solid gray', borderRadius:10}}>
            <div className='row'>
              <div className='col-12 col-md-6'>10 EL MAS SHANTA</div>
              <div className='col-12 col-md-6'>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label" size='small'>Postulante</InputLabel>
                  <Select
                    size='small'
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Postulante"
                    value={Categoria_10}
                    onChange={(e)=>setCategoria_10(e.target.value)}
                  >
                    {
                      userList.map((usr, index)=><MenuItem key={index} value={usr}>{usr}</MenuItem>)
                    }
                    

                  </Select>
                </FormControl>
              </div>
            </div>
          </div>

          <div className='col-12 py-3 my-2 text-center'>
            <button className='btn btn-success w-50' onClick={()=>handleSendData()}>Enviar</button>
          </div>
          
        </div>
      </div>
    </>
  ) */

  return (
    <>
      <HeaderMenu Pag_Sel={'home'} />
      <div className='container mt-5'>
        <div className='row'>
          <div className='col text-center'>
            <p className='display-4'>Las Votaciones han finalizado 😎</p>
            <p className='display-4'>este viernes veras los resultados🤓</p>
          </div>
        </div>
      </div>
    </>
  )
}
